<style lang="less">
.app-footer {
  text-align: center;
  padding: 10px 0 30px;
  color: rgba(0, 0, 0, 0.45);
  a {
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>

<template>
  <div class="app-footer">
    Copyright © {{ nowYear }} 修仙 · <a href="https://www.bilibili.com/bangumi/play/ss32544?spm_id_from=333.337.0.0" target="_blank"><i class="h-icon-github"></i></a> ·
    <a href="https://www.bilibili.com/bangumi/play/ss32544?spm_id_from=333.337.0.0" target="_blank">欲问长生同天寿</a> ·
    <a href="https://www.bilibili.com/bangumi/play/ss32544?spm_id_from=333.337.0.0" target="_blank">终不知，似蝼蚁！</a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nowYear: new Date().getFullYear()
    };
  }
};
</script>
